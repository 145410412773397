<template>
  <article id="wrap">

    <form novalidate="true">

      <header>
        <ul class="page_head">
          <li class="menuL" v-if="!isConfirm"><router-link :to="{name:'mypage'}"><img src="@/assets/img/icon_back.png"></router-link></li>
          <li class="menuL" v-if="isConfirm"><a @click="isConfirm = false;"><img src="@/assets/img/icon_back.png"></a></li>
          <li class="menuC"><h1 class="page_head_h1">会員情報変更</h1></li>
          <li class="menuR"></li>
        </ul>
      </header>


      <section v-if="!isConfirm" class="contents">

        <div class="contentInner pb_80">
          <SignupFormProfile ref="profile" v-bind.sync="form" mode="edit" @update:error="setError"></SignupFormProfile>
          <SignupFormSNS v-bind.sync="form" @update:error="setError"></SignupFormSNS>

          <div class="bt_form"><button type="button" value="send" @click="next">確認</button></div>

        </div><!--.contentInner-->

      </section><!--.contents-->

      <SignupConfirm v-if="isConfirm" v-bind="form" mode="edit" @send="send"></SignupConfirm>
      <AppFooter fixed></AppFooter>

    </form>

  </article>
</template>

<script>
import AppFooter from '@/components/AppFooter';
import SignupFormProfile from '@/components/SignupFormProfile';
import SignupFormSNS from '@/components/SignupFormSNS';
import SignupConfirm from '@/components/SignupConfirm';
export default {
  name: 'UserEdit',
  components: {AppFooter, SignupFormProfile, SignupFormSNS, SignupConfirm},
  data: function () {
    return {
      form: {
        nick: '',
        first_name: '',
        last_name: '',
        first_kana: '',
        last_kana: '',
        birthday: '',
        email: '',
        password: '',
        password_confirm: '',
        comment: '',
        twitter: '',
        facebook: '',
        instagram: '',
      },
      error: 0,
      isConfirm: false,
    }
  },
  computed: {
    loginUser () {
      return this.$store.state.loginUser;
    }
  },
  created() {
    this.form.nick = this.loginUser.nick;
    this.form.first_name = this.loginUser.first_name;
    this.form.last_name = this.loginUser.last_name;
    this.form.first_kana = this.loginUser.first_kana;
    this.form.last_kana = this.loginUser.last_kana;
    this.form.birthday = this.loginUser.birthday;
    this.form.email = this.loginUser.email;
    this.form.comment = this.loginUser.comment;
    this.form.twitter = this.loginUser.twitter;
    this.form.facebook = this.loginUser.facebook;
    this.form.instagram = this.loginUser.instagram;
  },
  methods: {
    next () {
      // validation
      this.error = this.$refs.profile.invalid();

      if (!this.error) {
        this.isConfirm = true;
        // this.$router.push({name:'signup-confirm'});
      } else {
        document.scrollingElement.scrollTop = 0;
      }
    },
    setError ($event) {
      this.error = $event;
    },
    async send () {
      console.log(this.form);
      const response = await this.$http.put('me', this.form);
      console.log(response);
      // this.$http.post('users', this.form);
      this.$store.commit('setLoginUser', response.data);
      this.$router.push({name: 'mypage'});
    }
  }
}
</script>
